<template>
  <div>
    <div class="section__common__container">
      <div 
        v-if="$screen.breakpoint === 'pc'"
        class="mt-20 mb-[120px] text-left"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div 
          class="text-5xl font-bold leading-[1.41] text-gray-700 mb-[59px]"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          지구 반대 편의 일상을 <br>
          우리만의 <span class="text-orange-500">재미</span>로 물들입니다.
        </div>
        <div class="flex flex-col">
          <div
            class="grid grid-cols-3 grid-rows-[100px,56px,100px]"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            <div class="border-l-[3px]">
              <div class="pl-8 mb-2.5 text-xl font-bold leading-[1.3] text-gray-500">
                하루 평균 유저 수
              </div>
              <div class="pl-8 text-6xl font-bold leading-[1.14] text-orange-500">
                150만 명 +
              </div>
            </div>
            <div class="border-l-[3px]">
              <div class="pl-8 mb-2.5 text-xl font-bold leading-[1.3] text-gray-500">
                한국 게임 인기 차트(구글, 애플 등)
              </div>
              <div class="pl-8 text-6xl font-bold leading-[1.14] text-orange-500">
                1위
              </div>
            </div>
            <div />
            <div />
            <div />
            <div />
            <div class="border-l-[3px]">
              <div class="pl-8 mb-2.5 text-xl font-bold leading-[1.3] text-gray-500">
                누적 유저 수
              </div>
              <div class="pl-8 text-6xl font-bold leading-[1.14] text-orange-500">
                4억 명 +
              </div>
            </div>
            <div class="border-l-[3px]">
              <div class="pl-8 mb-2.5 text-xl font-bold leading-[1.3] text-gray-500">
                서비스 국가 수
              </div>
              <div class="pl-8 text-6xl font-bold leading-[1.14] text-orange-500">
                250개국 +
              </div>
            </div>
            <div />
          </div>
        </div>
      </div>
      <div 
        v-else
        class="text-left tablet:mt-12 tablet:mb-[78px] mobile:mt-8 mobile:mb-16"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div 
          class="font-bold text-gray-700
                tablet:text-[36px] tablet:leading-[1.5] tablet:tracking-[-0.3px] tablet:mb-9 tablet:max-w-[466px]
                mobile:text-3xl mobile:leading-[1.46] mobile:mb-8 mobile:max-w-[343px]"
        >
          지구 반대 편의 일상을 <br>
          우리만의 <span class="text-orange-500">재미</span>로 물들입니다.
        </div>
        <div class="flex flex-col">
          <div class="border-l-[3px] tablet:mb-8 mobile:mb-6">
            <div 
              class="font-bold text-gray-500
                    tablet:pl-7 tablet:mb-2 tablet:text-lg tablet:leading-[1.33]
                    mobile:pl-4 mobile:mb-1 mobile:text-sm mobile:leading-[1.29]"
            >
              하루 평균 유저 수
            </div>
            <div 
              class="font-bold text-orange-500
                    tablet:pl-7 tablet:text-5xl tablet:leading-[1.23]
                    mobile:pl-4 mobile:text-4xl mobile:leading-[1.27]"
            >
              150만 명 +
            </div>
          </div>
          <div class="border-l-[3px] mb-8">
            <div 
              class="font-bold text-gray-500
                    tablet:pl-7 tablet:mb-2 tablet:text-lg tablet:leading-[1.33]
                    mobile:pl-4 mobile:mb-1 mobile:text-sm mobile:leading-[1.29]"
            >
              누적 유저 수
            </div>
            <div 
              class="font-bold text-orange-500
                    tablet:pl-7 tablet:text-5xl tablet:leading-[1.23]
                    mobile:pl-4 mobile:text-4xl mobile:leading-[1.27]"
            >
              4억 명 +
            </div>
          </div>
          <div class="border-l-[3px] tablet:mb-8 mobile:mb-6">
            <div 
              class="font-bold text-gray-500
                    tablet:pl-7 tablet:mb-2 tablet:text-lg tablet:leading-[1.33]
                    mobile:pl-4 mobile:mb-1 mobile:text-sm mobile:leading-[1.29]"
            >
              한국 게임 인기 차트(구글, 애플 등)
            </div>
            <div 
              class="font-bold text-orange-500
                    tablet:pl-7 tablet:text-5xl tablet:leading-[1.23]
                    mobile:pl-4 mobile:text-4xl mobile:leading-[1.27]"
            >
              1위
            </div>
          </div>
          <div class="border-l-[3px]">
            <div 
              class="font-bold text-gray-500
                    tablet:pl-7 tablet:mb-2 tablet:text-lg tablet:leading-[1.33]
                    mobile:pl-4 mobile:mb-1 mobile:text-sm mobile:leading-[1.29]"
            >
              서비스 국가 수
            </div>
            <div 
              class="font-bold text-orange-500
                    tablet:pl-7 tablet:text-5xl tablet:leading-[1.23]
                    mobile:pl-4 mobile:text-4xl mobile:leading-[1.27]"
            >
              250개국 +
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="widegames__container">
      <div 
        v-if="$screen.breakpoint === 'pc'"
        class="widegames"
      >
        <div
          class="bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' + require('@/assets/games/wide/tailedDemonSlayerBackground.png') + ')' }"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="relative overflow-hidden h-[680px]">
            <div class="w-[48%]">
              <div class="text-white w-[524px] mt-[170px] widegame__desc--left">
                <div class="self-center">
                  <div class="mb-3.5 text-5xl font-bold leading-[1.23]">
                    테일드 데몬 슬레이어
                  </div>
                  <div>
                    <div class="inline-block text-2xl font-bold leading-[1.27] text-orange-500">
                      Purple x Purple 스튜디오
                    </div>
                    <div class="inline-block w-[2px] h-[16px] border border-gray-300 mx-3" />
                    <div class="inline-block text-2xl font-bold leading-[1.27] text-orange-500">
                      400만 + 다운로드
                    </div>
                  </div>
                  <div class="text-xl leading-[1.8] mt-4 mb-8">
                    출시 후 단 3주 만에 양대 마켓 1위를 달성! 
                    <br>
                    단순한 방치형 장르에서 벗어나 컨트롤 기반의 UX, 
                    <br>
                    다채로운 성장 요소를 더한 것은 물론 스타일리시한 
                    <br>
                    카툰풍으로 변신을 시도한 RPG 게임입니다.
                  </div>
                  <div class="flex flex-row">
                    <a 
                      href="https://apps.apple.com/kr/app/%ED%85%8C%EC%9D%BC%EB%93%9C-%EB%8D%B0%EB%AA%AC-%EC%8A%AC%EB%A0%88%EC%9D%B4%EC%96%B4/id1587114188"
                      target="_blank"
                    >
                      <img 
                        src="@/assets/games/wide/appstore-large.png" 
                        alt="Download on the App Store"
                      >
                    </a>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.cookapps.taileddemonslayer"
                      target="_blank"
                    >
                      <img
                        class="ml-2.5" 
                        src="@/assets/games/wide/googleplay-large.png" 
                        alt="Get It On Google Play"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="absolute left-[50%] top-0 w-[1141px] h-[654px]">
              <img
                src="@/assets/games/wide/tailedDemonSlayer.png" 
                alt="Tailed Demon Slayer"
              >
            </div>
          </div>
        </div>
        <div 
          class="relative overflow-hidden mt-[39px] mb-[53px] h-[588px]"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="absolute right-[51%] w-[949px] h-[588px]">
            <img 
              src="@/assets/games/wide/sunnyHouse.png" 
              alt="Sunny House"
            >
          </div>
          <div class="w-full ml-[49%]">
            <div class="text-gray-700 w-[524px] mt-[155px] mb-[93px] widegame__desc--right">
              <div class="self-center">
                <div class="mb-3.5 text-5xl font-bold leading-[1.23]">
                  Merge Manor:써니 하우스
                </div>
                <div class="inline-block text-2xl font-bold text-orange-500">
                  Purple x Purple 스튜디오
                </div>
                <div class="inline-block w-[2px] h-[16px] border border-gray-300 mx-3" />
                <div class="inline-block text-2xl font-bold text-orange-500">
                  300만 + 다운로드
                </div>
                <div class="text-xl text-gray-600 leading-[1.8] mt-4 mb-8">
                  245개 국가에 진출해 하루에 13만 명이 넘는 유저들이 주인공
                  <br>
                  ‘선희’와 함께 여정을 보내는 캐주얼 게임 ‘써니하우스’. 2-Merge
                  <br>
                  장르로 고유의 게임성은 물론 유저의 취향을 반영한 화풍까지
                  <br>
                  고루 갖췄습니다.
                </div>
                <div class="flex flex-row">
                  <a 
                    href="https://apps.apple.com/us/app/merge-manor-sunny-house/id1573861950"
                    target="_blank"
                  >
                    <img 
                      src="@/assets/games/wide/appstore-large.png" 
                      alt="Download on the App Store"
                    >
                  </a>
                  <a 
                    href="https://play.google.com/store/apps/details?id=com.cookapps.sunnyhouse"
                    target="_blank"
                  >
                    <img
                      class="ml-2.5" 
                      src="@/assets/games/wide/googleplay-large.png" 
                      alt="Get It On Google Play"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' + require('@/assets/games/wide/fortressSagaBackground.png') + ')' }"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="relative overflow-hidden h-[680px]">
            <div class="w-[48%]">
              <div class="text-white w-[524px] mt-[212px] widegame__desc--left">
                <div class="self-center">
                  <div class="mb-3.5 text-5xl font-bold leading-[1.23]">
                    포트리스 사가
                  </div>
                  <div class="inline-block text-2xl font-bold leading-[1.27] text-orange-500">
                    Burger Monster 스튜디오
                  </div>
                  <div class="inline-block w-[2px] h-[16px] border border-gray-300 mx-3" />
                  <div class="inline-block text-2xl font-bold leading-[1.27] text-orange-500">
                    2023.08 런칭
                  </div>
                  <div class="text-xl leading-[1.8] mt-4 mb-8">
                    하드코어한 씬, 숨 가쁜 템포 등 그간 방치형 RPG 게임의 클리셰를
                    <br>
                    벗어난 ‘포트리스 사가’. Burger Monster 스튜디오만의 감각적인
                    <br>
                    제작 노하우를 집약해, 영웅의 후예 ‘루이’와 세계수의 씨앗 ‘코르’의 
                    <br>
                    모험기를 섬세하게 묘사해냈습니다.
                  </div>
                  <div class="flex flex-row">
                    <a 
                      href="https://apps.apple.com/kr/app/%ED%8F%AC%ED%8A%B8%EB%A6%AC%EC%8A%A4-%EC%82%AC%EA%B0%80-%EB%B0%A9%EC%B9%98%ED%98%95-rpg/id6446308106"
                      target="_blank"
                    >
                      <img 
                        src="@/assets/games/wide/appstore-large.png" 
                        alt="Download on the App Store"
                      >
                    </a>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.cookapps.bm.fortresssaga&hl=ko-KR"
                      target="_blank"
                    >
                      <img
                        class="ml-2.5" 
                        src="@/assets/games/wide/googleplay-large.png" 
                        alt="Get It On Google Play"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="absolute left-[48%] top-0 w-[1006px] h-[624px]">
              <img
                src="@/assets/games/wide/fortressSaga.png" 
                alt="Fortress Saga"
              >
            </div>
          </div>
        </div>
        <div 
          class="relative overflow-hidden my-[42px] h-[596px]"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="absolute right-[51%] w-[995px] h-[596px]">
            <img 
              src="@/assets/games/wide/zoeysJourney.png" 
              alt="Zoey's Journey"
            >
          </div>
          <div class="w-full ml-[49%]">
            <div class="text-gray-700 w-[524px] mt-[152px] mb-[104px] widegame__desc--right">
              <div class="self-center">
                <div class="mb-3.5 text-5xl font-bold leading-[1.23}">
                  Zoey's Journey
                </div>
                <div class="inline-block text-2xl font-bold text-orange-500">
                  Playgrounds 스튜디오
                </div>
                <div class="inline-block w-[2px] h-[16px] border border-gray-300 mx-3" />
                <div class="inline-block text-2xl font-bold text-orange-500">
                  2023.02 런칭
                </div>
                <div class="text-xl text-gray-600 leading-[1.8] mt-4 mb-8">
                  여러분의 선택에 따라 스토리 엔딩이 달라지는 ‘Zoey’s Journey’.
                  <br>
                  매치 3 퍼즐부터 캐릭터, 스토리, 인테리어까지 다채로운 콘텐츠
                  <br>
                  요소들을 조화롭게 구성해낸 인터랙티브 캐주얼 게임입니다.
                </div>
                <div class="flex flex-row">
                  <a 
                    href="https://apps.apple.com/us/app/zoeys-journey-love-pain/id1603012484"
                    target="_blank"
                  >
                    <img 
                      src="@/assets/games/wide/appstore-large.png" 
                      alt="Download on the App Store"
                    >
                  </a>
                  <a 
                    href="https://play.google.com/store/apps/details?id=com.cookapps.playgrounds.zoeysjourney"
                    target="_blank"
                  >
                    <img
                      class="ml-2.5"
                      src="@/assets/games/wide/googleplay-large.png" 
                      alt="Get It On Google Play"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' + require('@/assets/games/wide/unknownKnightsBackground.png') + ')' }"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="relative overflow-hidden h-[680px]">
            <div class="w-[48%]">
              <div class="text-white w-[524px] mt-[188px] widegame__desc--left">
                <div class="self-center">
                  <div class="mb-3.5 text-5xl font-bold leading-[1.23]">
                    무명기사단
                  </div>
                  <div>
                    <div class="inline-block text-2xl font-bold leading-[1.27] text-orange-500">
                      Burger Monster 스튜디오
                    </div>
                    <div class="inline-block w-[2px] h-[16px] border border-gray-300 mx-3" />
                    <div class="inline-block text-2xl font-bold leading-[1.27] text-orange-500">
                      2023.04 런칭
                    </div>
                  </div>
                  <div class="text-xl leading-[1.8] mt-4 mb-8">
                    전략 오토배틀러 장르의 묘미를 빠짐없이 담아낸, 무명 기사단.
                    <br>
                    여기에 더해 700년간의 수면기에서 깨어난 무명기사, 그와
                    <br>
                    함께 하는 다채로운 직업군의 기사단원들을 SD 도트 아트로
                    <br>
                    선보인 캐릭터 수집형 도트 RPG 게임입니다.
                  </div>
                  <div class="flex flex-row">
                    <a 
                      href="https://apps.apple.com/kr/app/%EB%AC%B4%EB%AA%85%EA%B8%B0%EC%82%AC%EB%8B%A8-%EB%8F%84%ED%8A%B8-%EC%95%A1%EC%85%98-rpg/id6443811741"
                      target="_blank"
                    >
                      <img 
                        src="@/assets/games/wide/appstore-large.png" 
                        alt="Download on the App Store"
                      >
                    </a>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.cookapps.bm.unknownknight"
                      target="_blank"
                    >
                      <img
                        class="ml-2.5" 
                        src="@/assets/games/wide/googleplay-large.png" 
                        alt="Get It On Google Play"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="absolute left-[50%] top-[73px] w-[1033px] h-[535px]">
              <img
                src="@/assets/games/wide/unknownKnights.png" 
                alt="Unknown Knights"
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
      >
        <div
          class="bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' + require('@/assets/games/wide/tailedDemonSlayerBackground.png') + ')' }"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="flex flex-col">
            <div class="w-full mx-auto tablet:mt-24 tablet:mb-16 mobile:mt-18 mobile:mb-14">
              <div class="flex flex-col text-white">
                <div class="self-center text-center">
                  <div 
                    class="font-bold 
                          tablet:mb-3 tablet:text-[36px] tablet:leading-[1.28] tablet:tracking-[-0.3px]
                          mobile:mb-2 mobile:text-3xl"
                  >
                    테일드 데몬 슬레이어
                  </div>
                  <div>
                    <div 
                      class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]"
                    >
                      Purple x Purple 스튜디오
                    </div>
                    <div class="inline-block border border-gray-300 tablet:w-[2px] tablet:h-[16px] tablet:mx-3 mobile:w-[1px] mobile:h-[11px] mobile:mx-2" />
                    <div 
                      class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]"
                    >
                      400만 + 다운로드
                    </div>
                  </div>
                  
                  <div 
                    class="mt-3 mx-auto
                          tablet:text-xl tablet:leading-[1.8] tablet:mb-8 tablet:max-w-[511px]
                          mobile:text-base mobile:leading-[1.69] mobile:mb-6 mobile:max-w-[343px]"
                  >
                    출시 후 단 3주 만에 양대 마켓 1위를 달성! 
                    <br>
                    단순한 방치형 장르에서 벗어나 컨트롤 기반의 UX, 
                    <br>
                    다채로운 성장 요소를 더한 것은 물론 스타일리시한 
                    <br>
                    카툰풍으로 변신을 시도한 RPG 게임입니다.
                  </div>
                  <div class="flex flex-row justify-center">
                    <a 
                      href="https://apps.apple.com/kr/app/%ED%85%8C%EC%9D%BC%EB%93%9C-%EB%8D%B0%EB%AA%AC-%EC%8A%AC%EB%A0%88%EC%9D%B4%EC%96%B4/id1587114188"
                      target="_blank"
                    >
                      <img
                        v-if="$screen.breakpoint === 'tablet'"
                        class="w-[156px]"
                        src="@/assets/games/wide/appstore-large-2x.png" 
                        alt="Download on the App Store"
                      >
                      <img
                        v-else
                        class="w-[123px]"
                        src="@/assets/games/wide/appstore-small-2x.png" 
                        alt="Download on the App Store"
                      >
                    </a>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.cookapps.taileddemonslayer&hl=ko&gl=US&pli=1"
                      target="_blank"
                    >
                      <img
                        v-if="$screen.breakpoint === 'tablet'"
                        class="ml-2.5 w-[176px]" 
                        src="@/assets/games/wide/googleplay-large-2x.png" 
                        alt="Get It On Google Play"
                      >
                      <img
                        v-else
                        class="ml-2.5 w-[139px]" 
                        src="@/assets/games/wide/googleplay-small-2x.png" 
                        alt="Get It On Google Play"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div 
              class="w-full mx-auto tablet:mb-14 mobile:mb-10"
            >
              <img
                v-if="$screen.breakpoint === 'tablet'"
                src="@/assets/games/wide/tailedDemonSlayer_tb.png" 
                alt="Tailed Demon Slayer"
              >
              <img
                v-else
                src="@/assets/games/wide/tailedDemonSlayer_mb.png" 
                alt="Tailed Demon Slayer"
              >
            </div>
          </div>
        </div>
        <div 
          class="flex flex-col"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="w-full mx-auto tablet:mt-24 tablet:mb-8 mobile:mt-18 mobile:mb-6">
            <div class="flex flex-col text-gray-700">
              <div class="self-center text-center">
                <div 
                  class="font-bold 
                        tablet:mb-3 tablet:text-[36px] tablet:leading-[1.28]
                        mobile:mb-2 mobile:text-3xl"
                >
                  Merge Manor:써니 하우스
                </div>
                <div>
                  <div 
                    class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]"
                  >
                    Purple x Purple 스튜디오
                  </div>
                  <div class="inline-block border border-gray-300 tablet:w-[2px] tablet:h-[16px] tablet:mx-3 mobile:w-[1px] mobile:h-[11px] mobile:mx-2" />
                  <div 
                    class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]"
                  >
                    300만 + 다운로드
                  </div>
                </div>
                <div 
                  class="text-gray-600 mt-3 mx-auto
                        tablet:text-xl tablet:leading-[1.8] tablet:mb-8 tablet:max-w-[524px]
                        mobile:text-base mobile:leading-[1.69] mobile:mb-6 mobile:max-w-[343px]"
                >
                  245개 국가에 진출해 하루에 13만 명이 넘는 유저들이 
                  <br
                    v-if="$screen.breakpoint === 'mobile'"
                  >
                  주인공
                  <br
                    v-if="$screen.breakpoint === 'tablet'"
                  >
                  ‘선희’와 함께 여정을 보내는 캐주얼 게임 
                  <br
                    v-if="$screen.breakpoint === 'mobile'"
                  >
                  ‘써니하우스’. 2-Merge
                  <br
                    v-if="$screen.breakpoint === 'tablet'"
                  >
                  장르로 고유의 게임성은 물론 
                  <br
                    v-if="$screen.breakpoint === 'mobile'"
                  >
                  유저의 취향을 반영한 화풍까지
                  <br
                    v-if="$screen.breakpoint === 'tablet'"
                  >
                  고루 갖췄습니다.
                </div>
                <div class="flex flex-row justify-center">
                  <a 
                    href="https://apps.apple.com/us/app/merge-manor-sunny-house/id1573861950"
                    target="_blank"
                  >
                    <img
                      v-if="$screen.breakpoint === 'tablet'"
                      class="w-[156px]"
                      src="@/assets/games/wide/appstore-large-2x.png" 
                      alt="Download on the App Store"
                    >
                    <img
                      v-else
                      class="w-[123px]"
                      src="@/assets/games/wide/appstore-small-2x.png" 
                      alt="Download on the App Store"
                    >
                  </a>
                  <a 
                    href="https://play.google.com/store/apps/details?id=com.cookapps.sunnyhouse"
                    target="_blank"
                  >
                    <img
                      v-if="$screen.breakpoint === 'tablet'"
                      class="ml-2.5 w-[176px]" 
                      src="@/assets/games/wide/googleplay-large-2x.png" 
                      alt="Get It On Google Play"
                    >
                    <img
                      v-else
                      class="ml-2.5 w-[139px]" 
                      src="@/assets/games/wide/googleplay-small-2x.png" 
                      alt="Get It On Google Play"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div 
            class="mx-auto w-full tablet:mb-[58px] mobile:mb-10"
          >
            <img 
              v-if="$screen.breakpoint === 'tablet'"
              src="@/assets/games/wide/sunnyHouse_tb.png" 
              alt="Sunny House"
            >
            <img 
              v-else
              src="@/assets/games/wide/sunnyHouse_mb.png" 
              alt="Sunny House"
            >
          </div>
        </div>
        <div
          class="bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' + require('@/assets/games/wide/fortressSagaBackground.png') + ')' }"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="flex flex-col">
            <div class="w-full mx-auto tablet:mt-24 tablet:mb-0 mobile:mt-18 mobile:mb-14">
              <div class="flex flex-col text-white">
                <div class="self-center text-center">
                  <div 
                    class="font-bold 
                          tablet:mb-3 tablet:text-[36px] tablet:leading-[1.28]
                          mobile:mb-2 mobile:text-3xl"
                  >
                    포트리스 사가
                  </div>
                  <div>
                    <div class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]">
                      Burger Monster 스튜디오
                    </div>
                    <div class="inline-block border border-gray-300 tablet:w-[2px] tablet:h-[16px] tablet:mx-3 mobile:w-[1px] mobile:h-[11px] mobile:mx-2" />
                    <div class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]">
                      2023.08 런칭
                    </div>
                  </div>
                  <div 
                    class="mt-3 mx-auto
                          tablet:text-xl tablet:leading-[1.8] tablet:mb-8 tablet:max-w-[511px]
                          mobile:text-base mobile:leading-[1.69] mobile:mb-6 mobile:max-w-[343px]"
                  >
                    하드코어한 씬, 숨 가쁜 템포 등 그간 방치형 RPG 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    게임의 클리셰를
                    <br
                      v-if="$screen.breakpoint === 'tablet'"
                    >
                    벗어난 ‘포트리스 사가’. Burger 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    Monster 스튜디오만의 감각적인
                    <br
                      v-if="$screen.breakpoint === 'tablet'"
                    >
                    제작 노하우를 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    집약해, 영웅의 후예 ‘루이’와 세계수의 씨앗 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    ‘코르’의
                    <br
                      v-if="$screen.breakpoint === 'tablet'"
                    >
                    모험기를 섬세하게 묘사해냈습니다.
                  </div>
                  <div class="flex flex-row justify-center">
                    <a 
                      href="https://apps.apple.com/kr/app/%ED%8F%AC%ED%8A%B8%EB%A6%AC%EC%8A%A4-%EC%82%AC%EA%B0%80-%EB%B0%A9%EC%B9%98%ED%98%95-rpg/id6446308106"
                      target="_blank"
                    >
                      <img
                        v-if="$screen.breakpoint === 'tablet'"
                        src="@/assets/games/wide/appstore-large.png" 
                        alt="Download on the App Store"
                      >
                      <img
                        v-else
                        src="@/assets/games/wide/appstore-small.png" 
                        alt="Download on the App Store"
                      >
                    </a>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.cookapps.bm.fortresssaga&hl=ko-KR"
                      target="_blank"
                    >
                      <img
                        v-if="$screen.breakpoint === 'tablet'"
                        class="ml-2.5" 
                        src="@/assets/games/wide/googleplay-large.png" 
                        alt="Get It On Google Play"
                      >
                      <img
                        v-else
                        class="ml-2.5" 
                        src="@/assets/games/wide/googleplay-small.png" 
                        alt="Get It On Google Play"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div 
              class="w-full mx-auto mb-10"
            >
              <img
                v-if="$screen.breakpoint === 'tablet'"
                src="@/assets/games/wide/fortressSaga_tb.png" 
                alt="Fortress Saga"
              >
              <img
                v-else
                src="@/assets/games/wide/fortressSaga_mb.png" 
                alt="Fortress Saga"
              >
            </div>
          </div>
        </div>
        <div 
          class="flex flex-col"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="w-full mx-auto tablet:mt-24 mobile:mt-18 tablet:mb-8 mobile:mb-14">
            <div class="flex flex-col text-gray-700">
              <div class="self-center text-center">
                <div 
                  class="font-bold 
                        tablet:mb-3 tablet:text-[36px] tablet:leading-[1.28]
                        mobile:mb-2 mobile:text-3xl"
                >
                  Zoey's Journey
                </div>
                <div>
                  <div 
                    class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]"
                  >
                    Playgrounds 스튜디오
                  </div>
                  <div class="inline-block border border-gray-300 tablet:w-[2px] tablet:h-[16px] tablet:mx-3 mobile:w-[1px] mobile:h-[11px] mobile:mx-2" />
                  <div 
                    class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]"
                  >
                    2023.02 런칭
                  </div>
                </div>  
                <div 
                  class="mt-3 text-gray-600 mx-auto
                        tablet:text-xl tablet:leading-[1.8] tablet:mb-8 tablet:max-w-[524px]
                        mobile:text-base mobile:leading-[1.69] mobile:mb-6 mobile:max-w-[343px]"
                >
                  여러분의 선택에 따라 스토리 엔딩이 달라지는 ‘Zoey’s 
                  <br
                    v-if="$screen.breakpoint === 'mobile'"
                  >
                  Journey’.
                  <br
                    v-if="$screen.breakpoint === 'tablet'"
                  >
                  매치 3 퍼즐부터 캐릭터, 스토리, 
                  <br
                    v-if="$screen.breakpoint === 'mobile'"
                  >
                  인테리어까지 다채로운 콘텐츠
                  <br
                    v-if="$screen.breakpoint === 'tablet'"
                  >
                  요소들을 조화롭게 
                  <br
                    v-if="$screen.breakpoint === 'mobile'"
                  >
                  구성해낸 인터랙티브 캐주얼 게임입니다.
                </div>
                <div class="flex flex-row justify-center">
                  <a 
                    href="https://apps.apple.com/us/app/zoeys-journey-love-pain/id1603012484"
                    target="_blank"
                  >
                    <img
                      v-if="$screen.breakpoint === 'tablet'"
                      class="w-[156px]"
                      src="@/assets/games/wide/appstore-large-2x.png" 
                      alt="Download on the App Store"
                    >
                    <img
                      v-else
                      class="w-[123px]"
                      src="@/assets/games/wide/appstore-small-2x.png" 
                      alt="Download on the App Store"
                    >
                  </a>
                  <a 
                    href="https://play.google.com/store/apps/details?id=com.cookapps.playgrounds.zoeysjourney"
                    target="_blank"
                  >
                    <img
                      v-if="$screen.breakpoint === 'tablet'"
                      class="ml-2.5 w-[176px]" 
                      src="@/assets/games/wide/googleplay-large-2x.png" 
                      alt="Get It On Google Play"
                    >
                    <img
                      v-else
                      class="ml-2.5 w-[139px]" 
                      src="@/assets/games/wide/googleplay-small-2x.png" 
                      alt="Get It On Google Play"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div 
            class="w-full mx-auto tablet:mb-12 mobile:mb-10"
          >
            <img 
              v-if="$screen.breakpoint === 'tablet'"
              src="@/assets/games/wide/zoeysJourney_tb.png" 
              alt="Zoey's Journey"
            >
            <img 
              v-else
              src="@/assets/games/wide/zoeysJourney_mb.png" 
              alt="Zoey's Journey"
            >
          </div>
        </div>
        <div
          class="bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' + require('@/assets/games/wide/unknownKnightsBackground.png') + ')' }"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div class="flex flex-col">
            <div class="w-full mx-auto tablet:mt-24 mobile:mt-18 tablet:mb-2 mobile:mb-10">
              <div class="flex flex-col text-white">
                <div class="self-center text-center">
                  <div 
                    class="font-bold 
                          tablet:mb-3 tablet:text-[36px] tablet:leading-[1.28]
                          mobile:mb-2 mobile:text-3xl"
                  >
                    무명기사단
                  </div>
                  <div>
                    <div class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]">
                      Burger Monster 스튜디오
                    </div>
                    <div class="inline-block border border-gray-300 tablet:w-[2px] tablet:h-[16px] tablet:mx-3 mobile:w-[1px] mobile:h-[11px] mobile:mx-2" />
                    <div class="inline-block font-bold text-orange-500 tablet:text-xl tablet:leading-[1.3] mobile:text-sm mobile:leading-[1.29]">
                      2023.04 런칭
                    </div>
                  </div>
                  <div 
                    class="mt-3 
                          tablet:text-xl tablet:leading-[1.8] tablet:mb-8 tablet:max-w-[511px]
                          mobile:text-base mobile:leading-[1.69] mobile:mb-6 mobile:max-w-[343px]"
                  >
                    전략 오토배틀러 장르의 묘미를 빠짐없이 담아낸, 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    무명 기사단.
                    <br
                      v-if="$screen.breakpoint === 'tablet'"
                    >
                    여기에 더해 700년간의 수면기에서 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    깨어난 무명기사, 그와
                    <br
                      v-if="$screen.breakpoint === 'tablet'"
                    >
                    함께 하는 다채로운 직업군의 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    기사단원들을 SD 도트 아트로
                    <br
                      v-if="$screen.breakpoint === 'tablet'"
                    >
                    선보인 캐릭터 
                    <br
                      v-if="$screen.breakpoint === 'mobile'"
                    >
                    수집형 도트 RPG 게임입니다.
                  </div>
                  <div class="flex flex-row justify-center">
                    <a 
                      href="https://apps.apple.com/kr/app/%EB%AC%B4%EB%AA%85%EA%B8%B0%EC%82%AC%EB%8B%A8-%EB%8F%84%ED%8A%B8-%EC%95%A1%EC%85%98-rpg/id6443811741"
                      target="_blank"
                    >
                      <img
                        v-if="$screen.breakpoint === 'tablet'"
                        class="w-[156px]"
                        src="@/assets/games/wide/appstore-large-2x.png" 
                        alt="Download on the App Store"
                      >
                      <img
                        v-else
                        class="w-[123px]"
                        src="@/assets/games/wide/appstore-small-2x.png" 
                        alt="Download on the App Store"
                      >
                    </a>
                    <a 
                      href="https://play.google.com/store/apps/details?id=com.cookapps.bm.unknownknight&pli=1"
                      target="_blank"
                    >
                      <img
                        v-if="$screen.breakpoint === 'tablet'"
                        class="ml-2.5 w-[176px]" 
                        src="@/assets/games/wide/googleplay-large-2x.png" 
                        alt="Get It On Google Play"
                      >
                      <img
                        v-else
                        class="ml-2.5 w-[139px]" 
                        src="@/assets/games/wide/googleplay-large-2x.png" 
                        alt="Get It On Google Play"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div 
              class="w-full mx-auto tablet:mb-12 mobile:mb-10"
            >
              <img
                v-if="$screen.breakpoint === 'tablet'"
                src="@/assets/games/wide/unknownKnights_tb.png" 
                alt="무명기사단"
              >
              <img
                v-else
                src="@/assets/games/wide/unknownKnights_mb.png" 
                alt="무명기사단"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WideGames',
};
</script>

<style lang="scss" scoped>
.widegame__desc--left {
  // margin-left: calc(24vw-230px);
  margin-left: Max(51px,calc((100vw - 1280px) / 2));
  // margin-left: Max(51px,calc(24vw-230px));
}
.widegame__desc--right {
  // margin-left: calc(25.5vw-240px);
  // margin-left: Max(51px,calc((100vw - 1280px) / 2));
  margin-left: Min(135.2px,calc(25.5vw - 240px));
  // margin-right: 320px;
}
</style>
