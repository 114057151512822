<template>
  <div class="section__common__container">
    <div 
      class="text-left font-bold leading-[1.27] text-gray-700 font-Pretendard 
            pc:pt-20 pc:pb-6 pc:text-4xl 
            tablet:pt-14 tablet:pb-8 tablet:text-4xl 
            mobile:pt-10 mobile:pb-4 mobile:text-3xl"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      쿡앱스 게임 더 보기
    </div>
    <div 
      class="grid pc:grid-cols-3 pc:gap-6 tablet:grid-cols-2 tablet:gap-6 mobile:grid-cols-1"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        v-for="game in games"
        :key="game.idx"
        class="pc:mb-12 tablet:mb-10 mobile:mb-14 game"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div>
          <img
            class="object-contain rounded-[10px]"
            :src="$screen.breakpoint === 'pc' ? game.img : game.tbImg" 
            :alt="game.title"
          >
        </div>
        <div class="">
          <div 
            class="font-bold text-gray-700 title
                  pc:mt-4 pc:text-3xl
                  tablet:mt-2
                  mobile:mt-4 mobile:text-2xl mobile:leading-[1.27]"
          >
            {{ game.title }}
          </div>
          <div 
            class="mt-2 text-orange-500 font-bold 
                  pc:text-base pc:leading-[1.25]
                  mobile:text-sm mobile:leading-[1.29]"
          >
            {{ game.downloadCnt }} 다운로드
          </div>
          <div 
            class="mt-2 text-gray-600
                  pc:text-lg pc:leading-[1.72]
                  mobile:text-base"
            style="word-break: keep-all;"
          >
            {{ game.desc }}
          </div>
          <div class="flex flex-row mt-5">
            <a 
              :href="game.appStore"
              target="_blank"
            >
              <img
                v-if="$screen.breakpoint === 'pc'"
                src="@/assets/games/wide/appstore-small.png" 
                alt="Download on the App Store"
              >
              <img
                v-else
                class="w-[123px]"
                src="@/assets/games/wide/appstore-small-2x.png" 
                alt="Download on the App Store"
              >
            </a>
            <a 
              :href="game.googlePlayStore"
              target="_blank"
            >
              <img
                v-if="$screen.breakpoint === 'pc'"
                class="ml-2" 
                src="@/assets/games/wide/googleplay-small.png" 
                alt="Get It On Google Play"
              >
              <img
                v-else
                class="ml-2 w-[139px]" 
                src="@/assets/games/wide/googleplay-small-2x.png" 
                alt="Get It On Google Play"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="games__container">
      <div
        v-for="game in games"
        :key="game.idx"
        class="flex gap-6 game"
      >
        <div class="game__img">
          <img
            :src="game.img"
            alt=""
          >
        </div>
        <div class="game__title">
          {{ game.title }}
        </div>
        <div class="game__downloadCnt">
          {{ game.downloadCnt }} DOWNLOADS
        </div>
        <div
          class="game__desc"
          v-html="game.desc"
        />
        <div class="game__icon">
          <img
            :src="game.icon"
            alt=""
          >
        </div>
        <div class="game__btnBox">
          <button
            class="customBtn md"
            :class="{ 'white' : $screen.breakpoint === 'pc' }"
            @click="moveToLink(game)"
          >
            <span>Play now</span>
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Games',
  data() {
    return {
      games: [
        {
          idx:0,
          img: require('@/assets/games/grid/idleBerserker.png'),
          tbImg: require('@/assets/games/grid/idleBerserker_tb.png'),
          title: '광전사 키우기 : 방치형 액션 RPG',
          downloadCnt: '100만 +',
          desc: '이계로 떨어진 이지우와 우루스!! 과연 그들은 \'크로노 라이프\'로 다시 돌아갈 수 있을까요?',
          googlePlayStore: 'https://play.google.com/store/apps/details?id=com.cookapps.berserkidle',
          appStore: 'https://apps.apple.com/kr/app/%EA%B4%91%EC%A0%84%EC%82%AC-%ED%82%A4%EC%9A%B0%EA%B8%B0/id1635339933'
        },
        {
          idx: 1,
          img: require('@/assets/games/grid/caribbeanLife.png'),
          tbImg: require('@/assets/games/grid/caribbeanLife_tb.png'),
          title: '꿈의 집:캐리비안 라이프',
          downloadCnt: '1,300만 +',
          desc: '다양한 퍼즐 레벨을 풀어서 방을 수리하고 장식하며 흥미진진한 의뢰인과 함께 에피소드를 플레이하세요!',
          googlePlayStore: 'https://play.google.com/store/apps/details?id=com.cookapps.caribbeanlife',
          appStore: 'https://apps.apple.com/us/app/home-design-caribbean-life/id1481217701'
        },
        {
          idx: 2,
          img: require('@/assets/games/grid/wonderMerge.png'),
          tbImg: require('@/assets/games/grid/wonderMerge_tb.png'),
          title: 'Wonder Merge',
          downloadCnt: '250만 +',
          desc: '불가사의한 세계로, 여행을 통해 타락한 땅을 마법으로 치유하고, 환상적인 생물들과 친구가 되어보세요!',
          googlePlayStore: 'https://play.google.com/store/apps/details?id=com.cookapps.wonder.merge.dragon.magic.evolution.merging.wondermerge',
          appStore: 'https://apps.apple.com/kr/app/%EC%9B%90%EB%8D%94-%EB%A8%B8%EC%A7%80-wonder-merge/id1511862816'
        },
        {
          idx:3,
          img: require('@/assets/games/grid/magicCatPianoTiles.png'),
          tbImg: require('@/assets/games/grid/magicCatPianoTiles_tb.png'),
          title: '매직 캣 피아노 타일',
          downloadCnt: '750만 +',
          desc: '귀여운 고양이, 사랑스러운 강아지 동물 타일로 멋진 피아니스트처럼 아름답게 피아노를 연주해 보세요!',
          googlePlayStore: 'https://play.google.com/store/apps/details?id=com.cookapps.beagles.magic.cat.piano.tiles',
          appStore: 'https://apps.apple.com/kr/app/%EB%A7%A4%EC%A7%81-%EC%BA%A3-%ED%94%BC%EC%95%84%EB%85%B8-%ED%83%80%EC%9D%BC/id1400670924'
        },
        {
          idx:4,
          img: require('@/assets/games/grid/luxuryInteriors.png'),
          tbImg: require('@/assets/games/grid/luxuryInteriors_tb.png'),
          title: '마이 홈 디자인 - 럭셔리 인테리어',
          downloadCnt: '1,500만 +',
          desc: '당신의 인테리어 디자인 스킬로 소파, 테이블, 카펫 등을 선택해 완벽한 럭셔리 인테리어를 완성하세요!',
          googlePlayStore: 'https://play.google.com/store/apps/details?id=com.cookapps.ff.luxuryinteriors',
          appStore: 'https://apps.apple.com/kr/app/myhome-design-luxury-interiors/id1524425572'
        },
        {
          idx:5,
          img: require('@/assets/games/grid/myFirstMakeover.png'),
          tbImg: require('@/assets/games/grid/myFirstMakeover_tb.png'),
          title: 'My First Makeover',
          downloadCnt: '450만 +',
          desc: '트렌디하고, 핫한 패션 디자이너가 될 수 있는 기회를 마이 퍼스트 메이크오버에서 만나보세요!',
          googlePlayStore: 'https://play.google.com/store/apps/details?id=com.cookapps.ff.myfirstmakeover',
          appStore: 'https://apps.apple.com/us/app/my-first-makeover/id1559664307'
        },
      ],
    };
  },
  methods: {
    moveToLink(data){
      const UA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
      if ( data.googlePlayStore && UA.indexOf('android') > -1) {
        window.open(data.googlePlayStore, '_blank');
      } else if ( data.appStore && UA.indexOf('iphone') > -1||UA.indexOf('ipad') > -1||UA.indexOf('ipod') > -1 ) {
        window.open(data.appStore, '_blank');
      } else {
        window.open(data.googlePlayStore, '_blank');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:is(.pc) {
  .game:nth-child(n + 4) {
    margin-top: 16px;
  }
}
:is(.tablet) {
  .game:nth-child(n + 5) {
    margin-bottom: 0px;
  }
}
:is(.mobile) {
  .game:last-child {
    margin-bottom: 0px;
  }
}
// .games__container{
//     width: 100%;
// }
// .game{
//     transition: transform .4s;
//     background-color: #FFF;
//     position: relative;
//     display: inline-block;
//     overflow: hidden;
//     width: calc( ( 100% - 2rem ) / 3 );
//     height: 0; padding-bottom: 36.5%;
//     border-radius: rem(36);
//     margin-right: 1rem;
//     margin-bottom: 1rem;
//     box-shadow: 0.6px 0.7px 9px 2px rgba($color: #000000, $alpha: .1);
//     &:nth-child(3n){
//         margin-right: 0;
//     }
//     &__img{
//         overflow: hidden;
//         position: relative;
//         width: 100%;
//         height: 0;
//         padding-bottom: 49%;
//         img{
//             pointer-events: none;
//             transition: transform .4s;
//             position: absolute;
//             width: 100%; height: 100%;
//             object-fit: cover;
//             background-color: $gray-300;
//         }
//     }
//     &__title{
//         padding: 5% 7% 0;
//         font-size: 26px;
//         line-height: 1;
//         font-weight: 900;
//         font-family: $fontfamilyKR;
//     }
//     &__downloadCnt{
//         padding: 2% 7% 4%;
//         font-size: 18px;
//         color: $primaryColor;
//         line-height: 1;
//         font-weight: 900;
//         font-family: $fontfamilyKR;
//     }
//     &__desc{
//         padding: 0 7%;
//         font-size: 1.08rem;
//         line-height: 1.4;
//         word-break: keep-all;
//     }
//     &__icon{
//         -webkit-mask-image: -webkit-radial-gradient(white, black);
//         position: absolute;
//         width: 60px; height: 60px;
//         bottom: 4%; right: 7%;
//         border-radius: 15px;
//         overflow: hidden;
//         img{
//             transition: all .4s;
//             user-select: none;
//             pointer-events: none;
//         }
//     }
//     &__btnBox{
//         position: absolute;
//         bottom: 4%; left: 7%;
//     }
//     &:hover{
//         transform: scale(1.04);
//         .game__icon img{
//             transform: scale(1.15);
//         }
//         .game__img img{
//             transform: scale(1.1);
//         }
//     }
// }
// :is(.tablet) {
//     .game{
//         width: calc( ( 100% - 1rem ) / 2 );
//         padding-bottom: 58%;
//         margin-right: 1rem;
//         &:nth-child(2n){
//             margin-right: 0;
//         }
//     }
// }
// :is(.mobile) {
//     .game{
//         width: 100%;
//         margin-right: 0 !important;
//         padding-bottom: 111%;
//     }
//     .game__title{
//         padding: 5% 7% 0;
//         font-size: 1.2rem;
//     }
//     .game__downloadCnt{
//         padding: 2% 7% 4%;
//         font-size: .9rem;
//     }
//     .game__desc{
//         font-size: .94rem;
//     }
//     .game__icon{
//         right: 6%;
//     }
//     .game__img:hover img{
//         transform: none;
//     }
// }
</style>
