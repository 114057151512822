<template>
  <div>
    <div
      style="background-color: #ffffff;"
      class="commonPagePaddingBottom"
    >
      <!-- <PageTopBanner :data="gameTopBanner" /> -->
      <WideGames />
      <!-- <div class="h-1 border-b" /> -->
      <!-- <GameHistoryBanner /> -->
      <Games />
    </div>
    <JobSection />
    <!-- <ConfirmModal /> -->
    <!-- <SurveyModal /> -->
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
import WideGames from './components/wideGames.vue';
// import GameHistoryBanner from './components/gameHistoryBanner.vue';
import Games from './components/games.vue';
import JobSection from './components/jobSection.vue';
// import ConfirmModal from '../../components/modal/confirmModal.vue';
// import SurveyModal from '../../components/modal/surveyModal.vue';

export default {
  name: 'Game',
  components: {
    // PageTopBanner,
    WideGames,
    // GameHistoryBanner,
    Games,
    JobSection,
    // ConfirmModal,
    // SurveyModal,
  },
  data() {
    return {
      gameTopBanner: {
        bg: require('@/assets/games/gamePage__bannerBg.png'),
        title: 'Our Games',
        subtitle: '<b>세상을 즐겁게</b> 만드는 <b>쿡앱스의 게임을 소개</b>합니다!',
        lottie: require('@/assets/lottie/gameTopBanner.json'),
        lottieMobile: require('@/assets/lottie/gameTopBanner_m.json'),
      }
    };
  },
  created(){
    window.fbq('track', 'cookapps_games_view');
  }
};
</script>
